import React from 'react';
import {ThemeProvider} from "@mui/material/styles";
import {Provider} from "react-redux";
import {setRefreshId, setSessionId} from "./store/slices/auth";
import {persistor, store} from "./store/store";
import {PersistGate} from "redux-persist/integration/react";
import {HashRouter, Route, Routes} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {LicenseInfo} from '@mui/x-license-pro';

import {
    SnackbarUtilsConfigurator
} from "@blg/blg-core/lib/esm/components";
import {theme} from "./styles/theme";

import HomeView from "./views/home.view";
import {ROUTES} from "./routes/routes";
import LoginView from "./views/login.view";
import DefaultView from "./views/default.view";
import StackOptionsView from "./views/stackOptions.view";
import StackView from "./views/stack/stack.view";
import UnstackView from "./views/unstack/unstack.view";
import { I18nextProvider } from 'react-i18next';
import i18n from "./i18n";
import StackDetailView from "./views/stack/stackDetail.view";
import UnStackDetailView from "./views/unstack/unStackDetail.view";
import {setLauncherParams} from "./store/slices/global";
import { getCookieURLDomain } from '@blg/blg-core';

declare global {
    interface Window {
        RFIDConnector: any;
        cordova: any;
        api: any,
        cookieMaster: any;
    }
    interface Navigator {
        app: any;
        device: any;
    }
}

function App() {

    (window as any)["handleOpenURL"] = (url: string) => {
        const splitParams = url.split('?')[1];
        const urlSearchParams = new URLSearchParams(splitParams);
        const params = Object.fromEntries(urlSearchParams.entries());
        const env = getCookieURLDomain();
        const launcherParams = {
            isLauncher: params.launcher === 'true',
            isTug: params.tugApp === 'true',
            path: params.path
        }
        store.dispatch(setLauncherParams(launcherParams));
        if (params.session) {
            const sessionId = params.session;
            store.dispatch(setSessionId(sessionId));
            window.cookieMaster.setCookieValue(env, 'JCARS_SESSION_ID', sessionId, (response: any) => {
                console.log('setCookieValue JCARS_SESSION_ID:', response);
            }, (error: any) => {
                if (error) {
                    console.log('error: ' + error);
                }
            });
        }
        if (params.refresh) {
            const refreshId = params.refresh;
            store.dispatch(setRefreshId(refreshId));
            window.cookieMaster.setCookieValue(env, 'JCARS_REFRESH_ID', refreshId, (response: any) => {
                console.log('setCookieValue JCARS_REFRESH_ID:', response);
            }, (error: any) => {
                if (error) {
                    console.log('error: ' + error);
                }
            });
        }
    }

    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY!);

    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3}
                                  hideIconVariant={false}
                                  anchorOrigin={{horizontal: "center", vertical: "bottom"}}>
                    <SnackbarUtilsConfigurator/>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <HashRouter>
                                <Routes>
                                    <Route path={ROUTES.ROOT} element={<DefaultView/>}>
                                        <Route path={ROUTES.LOGIN} element={<LoginView/>}/>
                                        <Route path={ROUTES.HOME} element={<HomeView/>}>
                                            <Route index element={<StackOptionsView />} />
                                            <Route path={ROUTES.STACK} element={<StackView />} />
                                            <Route path={ROUTES.STACK_DETAILS} element={<StackDetailView />} />
                                            <Route path={ROUTES.UNSTACK} element={<UnstackView />} />
                                            <Route path={ROUTES.UNSTACK_DETAILS} element={<UnStackDetailView />} />
                                        </Route>
                                    </Route>
                                </Routes>
                            </HashRouter>
                        </PersistGate>
                    </Provider>
                </SnackbarProvider>
            </ThemeProvider>
        </I18nextProvider>
    );
}

export default App;
