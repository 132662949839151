import {UserModel} from '@blg/blg-core';
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface AuthState {
    username?: string,
    sessionId?: string,
    refreshId?: string,
    user?: UserModel
}

const initialState: AuthState = {
    user: undefined,
    username: undefined,
    sessionId: undefined,
    refreshId: undefined
};

const authState = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state: AuthState, action: PayloadAction<UserModel>) {
            state.user = action.payload
        },
        setUsername(state: AuthState, action: PayloadAction<string>) {
            state.username = action.payload
        },
        setSessionId(state: AuthState, action: PayloadAction<string>) {
            state.sessionId = action.payload;
        },
        setRefreshId(state: AuthState, action: PayloadAction<string>) {
            state.refreshId = action.payload;
        }
    },
});

export const {setUsername, setSessionId, setRefreshId, setUser} = authState.actions;
export default authState.reducer;
