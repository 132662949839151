import React, {useState} from 'react';
import {Box} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {Outlet} from "react-router-dom";
import { setLauncherParams } from 'store/slices/global';
import CheckAuthComponent from "../components/auth/checkAuth.component";
import {ErrorBoundary} from "react-error-boundary";
import { ErrorFallbackComponent } from '@blg/blg-core';
import {store} from "../store/store";

function DefaultView() {
    const theme = useTheme();
    const [errorState, setErrorState] = useState(0);

    if (window.api) {
        window.api.receiveDeeplink((data: any) => {
            console.log('received data', data);
            if (data.queryParams) {
                const fixedQueryData = data.queryParams.replace(/"/g, "");
                const queryData = new URLSearchParams(fixedQueryData);
                const parsedData = Object.fromEntries(queryData.entries());
                console.log("parsed", parsedData);

                const launcherParams = {
                    isLauncher: parsedData.launcher === 'true',
                    isTug: parsedData.tugApp === 'true',
                    path: parsedData.path
                }
                store.dispatch(setLauncherParams(launcherParams));
            }
        });
    }

    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.main, height: "100vh", width: "100%",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
            <CheckAuthComponent />
            <ErrorBoundary
                key={errorState}
                onReset={() => {
                    setErrorState(prevState => prevState + 1);
                }}
                FallbackComponent={ErrorFallbackComponent}>
                <Outlet/>
            </ErrorBoundary>
        </Box>
    );
}

export default DefaultView;
