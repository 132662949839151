import React, {useEffect, useRef, useState} from "react";
import {Backdrop, Box} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes/routes";
import {useAppSelector} from "../../store/hooks";
import {setUser} from "../../store/slices/auth";
import {store} from "../../store/store";
import LoadingImage from "../../assets/images/loading-animation.svg";
import {AuthService, EnvironmentService, UserModel} from '@blg/blg-core';

const CheckAuthComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const sessionId = useAppSelector(state => state.auth).sessionId;
    const launcherParams = useAppSelector(state => state.global).launcherParams;
    const logoutTimer = useRef<NodeJS.Timeout | null>(null);

    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);

    useEffect(() => {
        setShowLoadingAnimation(true);
        return () => {
            setShowLoadingAnimation(false);
        }
    }, []);

    useEffect(() => {
        async function checkAuth() {
            try {
                await AuthService.instance.checkAuth(process.env.REACT_APP_X_APP_ID!);
                if (logoutTimer && logoutTimer.current) {
                    clearTimeout(logoutTimer.current);
                }

                const environmentResponse = await EnvironmentService.instance.getEnvironment();
                const user = UserModel.parseFromObject(environmentResponse.data.data.user) as UserModel;
                store.dispatch(setUser(user));

                setShowLoadingAnimation(false);
                if (location.pathname === '/login' || location.pathname === '/') {
                    navigate(ROUTES.HOME);
                } else {
                    navigate(location.pathname);
                }
            } catch (e: any) {
                if (!logoutTimer.current) {
                    logoutTimer.current = setTimeout(() => {
                        goToLogin();
                    }, 1500);
                }
            }
        }

        // Wait for the cookie to be saved to the redux-store
        // Or, if it is not the launcher, also check the auth status
        if (sessionId || !launcherParams?.isLauncher) {
            void checkAuth();
        }
    }, [sessionId, launcherParams]);

    const goToLogin = () => {
        localStorage.removeItem(`persist:${process.env.REACT_APP_STORE_KEY}`);
        setShowLoadingAnimation(false);
        navigate(ROUTES.LOGIN);
    };

    return (
        <Box sx={{position: "absolute", top: 0, left: 0}}>
            <Backdrop
                sx={{
                    backgroundColor: "rgba(222,224,233,0.9)",
                    color: '#fff',
                    zIndex: 10000
                }}
                open={showLoadingAnimation}
            >
                <object type="image/svg+xml" style={{width: 275, height: 275}} data={LoadingImage}>svg-animation
                </object>
            </Backdrop>
        </Box>
    )
};

export default CheckAuthComponent;
