import {DefaultComponent, LoginComponent, SnackbarUtil} from "@blg/blg-core/lib/esm/components";
import UserModel from "@blg/blg-core/lib/esm/models/user.model";
import {Theme, useMediaQuery} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../routes/routes";
import {useAppSelector} from '../store/hooks';
import {setUser} from "../store/slices/auth";
import {store} from "../store/store";

function Login() {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const user = useAppSelector(state => state.auth).user;
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
    const [usedLogin, setUsedLogin] = useState(false);

    function handleError(error: string) {
        SnackbarUtil.error(error);
    }

    useEffect(() => {
        window.history.replaceState(null, 'login');
        store.dispatch({type: 'CLEAR_STORE'});
    }, [])

    async function handleSuccess(user: UserModel) {
        setUsedLogin(true);
        store.dispatch(setUser(user));
        await i18n.changeLanguage(user.locale);
        const referrer = document.referrer;
        if (referrer.length > 0) {
            const url = new URL(referrer);
            const targetUrl = `${url.pathname}?${url.searchParams}`;
            window.open(targetUrl, '_self')
        }
    }

    useEffect(() => {
        // Only navigate to the home view, after using the login
        // Else on opening the login as logged-in user, the user also gets redirected to home
        if (user && usedLogin) {
            navigate(ROUTES.HOME);
        }
    }, [user, usedLogin]);

    return (
        <DefaultComponent showLoadingAnimation={showLoadingAnimation}>
            <LoginComponent title={t('GENERAL.APP_TITLE')} onSuccess={handleSuccess}
                            hideLegalLinks={isSmallScreen}
                            onError={handleError}
                            showLoading={setShowLoadingAnimation}></LoginComponent>
        </DefaultComponent>

    );
}

export default Login;
