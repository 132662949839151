import {ButtonProps} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import React from "react";

export const defaultStyles = {
    contentBox: {
        borderRadius: "6px",
        backgroundColor: "#FFF"
    },
}

export function defaultTableStyle() {
    return {
        height: "100%",
        backgroundColor: "#FFF",
        '& .cargotable-centeredHeader': {
            backgroundColor: "#FFF",
            '.MuiDataGrid-columnHeaderTitleContainer': {
                padding: 0,
                justifyContent: 'center'
            },
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
            }
        },
        '& .cargotable-header': {
            backgroundColor: "#FFF",
            '.MuiDataGrid-columnHeaderTitleContainer': {
                padding: 0,
            },
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
            }
        },
    }
}

export const defaultButtonProps: ButtonProps = {
    variant: 'contained',
    color: 'primary',
    size: 'large',
};

export const secondaryButtonProps: ButtonProps = {
    variant: 'outlined',
    color: 'primary',
    size: 'large'
}

export const defaultShadow = {
    boxShadow: '-5px 10px 20px rgba(54, 105, 168, 0.25)',
}

export const lightShadow = {
    boxShadow: '0px 30px 40px rgba(54, 105, 168, 0.1)',
}

export const defaultButtonStyle = {
    textTransform: 'none',
    width: '100%',
    height: '74px',
    borderRadius: '6px',
    fontSize: '18px',
    fontWeight: '500',
    ...defaultShadow,
    "&.Mui-disabled": {
        backgroundColor: "rgba(54, 105, 168, 0.25)",
        color: "#FFF"
    }
}

export const defaultSelectProps = {
    style: {
        height: '74px'
    },
    IconComponent: () => (<ExpandMore fontSize="large" style={{color: '#757575', marginRight: 5}}/>)
}

export const secondaryButtonStyle = {
    textTransform: 'none',
    width: '100%',
    height: '74px',
    borderRadius: '6px',
    fontSize: '18px',
    fontWeight: '500',
    borderWidth: 2,
    "&.Mui-disabled": {
        color: "#FFF"
    }
};
