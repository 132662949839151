import {
    createTheme,
    PaletteColor,
    PaletteColorOptions,
} from '@mui/material/styles';
import {deDE as coreDeDE} from '@mui/material/locale';
import {deDE} from '@mui/x-data-grid/locales';
import {blgThemeColors} from '@blg/blg-core/lib/esm/components/theme/parts/colors';
import {blgThemeTypography} from '@blg/blg-core/lib/esm/components/theme/parts/typography';
import {blgThemeComponents} from '@blg/blg-core/lib/esm/components/theme/parts/components';
import {BlgDefaultPalette} from '@blg/blg-core/lib/esm/interfaces/BlgDefaultPalette.interface';

const {palette} = createTheme();
const {augmentColor} = palette;
const createColor = (mainColor: string) => augmentColor({color: {main: mainColor}});

declare module '@mui/material/styles' {
    interface CustomPalette extends BlgDefaultPalette {
        surface: PaletteColorOptions;
        yellowText: PaletteColorOptions;
        white: PaletteColorOptions;
    }

    // eslint-disable-next-line
    interface Palette extends CustomPalette {
    }

    // eslint-disable-next-line
    interface PaletteOptions extends CustomPalette {
    }

    interface Theme {
        palette: { [key in keyof CustomPalette]: PaletteColor } & Palette,
    }
}

declare module "@mui/material/IconButton" {
    interface IconButtonPropsColorOverrides extends BlgDefaultPalette {
        white: true
    }
}

const extendedBlgThemeColors = {
    ...blgThemeColors,
    surface: blgThemeColors.blueLight,
    yellowText: createColor("#FFD600"),
    white: createColor("#FFF"),
    lockedMafiColor: createColor("#FFF"),
};

export const theme = createTheme({
    palette: extendedBlgThemeColors,
    typography: blgThemeTypography,
    components: {
        ...blgThemeComponents,
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: "none"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontStyle: "italic",
                    fontSize: "15px",
                    paddingTop: "2px",
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    color: '#3669A8'
                },
                root: {
                    "&.Mui-focused": {
                        "& legend": {
                            paddingRight: "5px !important",
                        },
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    display: "block",
                    position: "absolute",
                    bottom: 0
                },
            }
        },
    }
}, deDE, coreDeDE);
